<template>
  <div>
    <div class="card query">
      <input type="text" placeholder="项目名称" v-model="name" />
      <input type="text" placeholder="业主" v-model="owner" />
      <input type="text" placeholder="项目地址" v-model="address" />
      <select v-model="createBy" v-if="isAdmin" @change="query">
        <option v-for="item in userList" :key="item.id" :value="item.id">
          {{ item.name }}
        </option>
      </select>
      <!-- <input type="date" placeholder="更新日期" v-model="date" /> -->

      <div class="button-group">
        <button class="primary-button" @click="query">查询</button>
        <button class="reset-button" @click="reset">重置</button>
      </div>

      <button class="add" @click="$router.push($route.path + '/add')">
        创建项目
      </button>
    </div>

    <div style="padding-top: 30px"></div>

    <div class="card">
      <a-skeleton v-if="loading" />

      <div v-else>
        <a-empty v-if="list.length === 0" />

        <table v-else class="table">
          <tr class="header">
            <td>项目名称</td>
            <td>业主</td>
            <td>项目地址</td>
            <td>更新日期</td>
            <td v-if="isAdmin">项目创建账号</td>
            <td class="center">操作</td>
          </tr>

          <tr v-for="item in list" :key="item.id" class="row">
            <td>{{ item.name }}</td>
            <td>{{ item.owner }}</td>
            <td>{{ item.address }}</td>
            <td>{{ item.modifyAt }}</td>
            
            <td v-if="isAdmin">{{ renderCreateBy(item.createBy) }}</td>
            <td class="control center">
              <span @click="$router.push('/task?projectId=' + item.id)"
                >查看</span
              >
              <span
                style="
                  margin: 0 4px;
                  height: 1em;
                  width: 2px;
                  background-color: #0667fd;
                "
              ></span>
              <span @click="edit(item)">编辑</span>
              <span
                style="
                  margin: 0 4px;
                  height: 1em;
                  width: 2px;
                  background-color: #0667fd;
                "
              ></span>
              <span @click="download(item)">下载</span>
              <span
                style="
                  margin: 0 4px;
                  height: 1em;
                  width: 2px;
                  background-color: #0667fd;
                "
              ></span>
              <span @click="removeItem(item)">删除</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
function fetchUserList(params) {
  return request({
      url: '/user/list',
      params
  })
}
import { fetchList, remove } from "@/api/project";
import { fetchList as fetchTaskList } from "@/api/task";
import request from '@/api/request.js'
export default {
  data() {
    return {
      userList: [],
      name: "",
      owner: "",
      address: "",
      createBy: "",
      loading: false,
      list: [],
      isAdmin: false,
      visible: false,
      tasks: [],
    };
  },

  mounted() {
    if(localStorage.getItem("mobile") == "18066102324") {
      this.isAdmin = true;
    }else {
      this.isAdmin = false;
    }
    fetchUserList({
      pageNum: 1,
      pageSize: 999,
    }).then((res) => {
      if (Array.isArray(res.list)) {
        this.userList = Object.freeze(res.list);
        // this.total = res.totalSize;
        this.getList();
      }
    })
    .finally(() => {
    });
  },

  methods: {
    renderCreateBy(id) {
      const list = this.userList.filter(v=>v.id==id);
      if(list.length > 0){
        return list[0].name;
      }else{
        return ""
      }
    },
    getList() {
      this.loading = true;
      fetchList({
        pageNum: 1,
        pageSize: 999,
        name: this.name,
        owner: this.owner,
        address: this.address,
        createBy: this.createBy
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            // this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    query() {
      this.getList();
    },
    reset() {
      this.name = "";
      this.owner = "";
      this.address = "";
      this.createBy = "";
      this.getList();
    },
    edit(item) {
      this.$router.push(this.$route.path + "/add?id=" + item.id);
    },
    download(item) {
      this.activeProject = item;
      this.visible = true;
      fetchTaskList({
        pageNum: 1,
        pageSize: 9999,
        projectId: item.id,
      }).then((res) => {
        if (res && Array.isArray(res.list)) {
          const tasks = res.list
            .map((item) => item.bimFile)
            .filter((item, index, self) => self.indexOf(item) === index);
          if (tasks.length > 0) {
            tasks.forEach((item) => {
              window.open(item);
            });
          } else {
            this.$message.error("没有可下载的单体");
          }
        }
      });
    },
    removeItem(item) {
      const that = this;
      this.$confirm({
        title: "是否删除？",
        okText: "是",
        okType: "danger",
        cancelText: "否",
        onOk() {
          remove({ id: item.id }).then(() => {
            that.getList();
          });
        },
      });
    }
  },
};
</script>

<style lang="less" scoped>
.card {
  background-color: #fff;
  box-shadow: 0px 6px 26px 0px rgba(36, 80, 154, 0.15);
  border-radius: 20px;
  padding: 40px;
}
.query {
  display: flex;
  align-items: center;
  gap: 10px;

  input {
    width: 177px;
    height: 40px;
    padding: 0 12px;
    background: #f8f9fb !important;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
  }
  input::placeholder {
    font-size: 16px;
    font-weight: 500;
    color: #abb3c0;
  }
  select {
    width: 177px;
    height: 40px;
    padding: 0 12px;
    background: #f8f9fb !important;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
  }

  .button-group {
    .primary-button {
      width: 71px;
      height: 38px;
      line-height: 100%;
      color: #fff;
      background: linear-gradient(146deg, #0c99ff 0%, #0560fd 100%);
      box-shadow: 0px 5px 8px 0px rgba(5, 96, 253, 0.32);
      border-radius: 6px 0px 0px 6px;
    }

    .reset-button {
      width: 71px;
      height: 38px;
      background: #fff;
      border-radius: 6px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border-top: 3px solid #eff1f6;
      border-right: 3px solid #eff1f6;
      border-bottom: 3px solid #eff1f6;
      box-sizing: border-box;
    }
  }
}

.add {
  margin-left: auto;
  width: 156px;
  height: 38px;
  line-height: 100%;
  color: #fff;
  background: linear-gradient(146deg, #0c99ff 0%, #0560fd 100%);
  box-shadow: 0px 5px 8px 0px rgba(5, 96, 253, 0.32);
  border-radius: 6px;
}

.table {
  width: 100%;
  .header {
    opacity: 0.7;

    td {
      padding: 16px;
      background: #ebf2fc;
      font-weight: 400;
      color: #9ca4b1;
      font-size: 16px;
    }
    td:first-child {
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
    }
    td:last-child {
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
    }
  }

  .row {
    border-bottom: 1px solid rgba(207, 220, 238, 0.71);

    td {
      padding: 16px;
    }
  }
}

.status {
  display: flex;
  align-items: center;
  gap: 6px;
  img {
    height: 16px;
  }
}

.control {
  color: #0667fd;
  cursor: pointer;
}
</style>